






































































import Vue from "vue";
import { dispatch, ListQuery } from "@/store";
import { download, xlsx } from "@/utils";
import moment from "moment";
import { CouponCategory, CouponStatus } from "@/enum";

export default Vue.extend({
  props: {
    product: String
  },
  data() {
    return {
      dispatch,
      loading: false,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "-id",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      fields: [
        {
          title: "批量生成数量",
          dataIndex: "number",
          type: "number",
          min: 1,
          max: 100000,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入生成数量"
            }
          ]
        }
      ]
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "用户 ID",
          dataIndex: "user_id"
        },
        {
          title: "券码分类",
          dataIndex: "category",
          customRender: (text: string) => (CouponCategory as any)[text]
        },
        {
          title: "券码类型",
          dataIndex: "type",
          customRender: (
            type: string,
            { amount_threshold, discount, save_amount, max_amount_limit }: any
          ) => {
            let text = "";
            if (amount_threshold) {
              text += "满 " + amount_threshold + " ";
            } else {
              text += "无门槛";
            }
            if (type === "discount") {
              text += "打 " + discount / 10 + " 折";
              if (max_amount_limit) {
                text += "，最多优惠 " + max_amount_limit;
              }
            } else if (type === "save") {
              text += "减 " + save_amount;
            } else if (type === "early_bird") {
              text = "必购码";
            }
            return text;
          }
        },
        {
          title: "券码",
          dataIndex: "coupon_code",
          customRender: (text: string) => text || "链接领取无券码"
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text: string) => (CouponStatus as any)[text]
        },
        {
          title: "领取时间",
          dataIndex: "claim_at",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "使用时间",
          dataIndex: "use_at",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "80px",
          align: "center"
        }
      ];
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetUserCouponList({
          product_code: this.product,
          ...query
        })
        .then(res => {
          this.list = res.results;
          this.query = {
            ...query,
            page_number: res.page_number,
            page_size: res.page_size
          };
          this.count = res.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onCancel(id: number) {
      this.$confirm({
        title: "确认将此券作废？",
        centered: true,
        onOk: () => {
          dispatch.couponsCancelUserCoupon(id).then(() => {
            this.$message.success("作废成功");
            this.getList(this.query);
          });
        }
      });
    },
    onExport() {
      this.$message.info("用户优惠券信息导出中…");
      this.loading = true;
      let list: any[] = [];
      const getList = (pageNumber: number) =>
        dispatch
          .couponsGetUserCouponList({
            ...this.query,
            page_number: pageNumber,
            page_size: 1000
          })
          .then(res => {
            list = [...list, ...res.results];
            if (res.count > list.length) {
              getList(pageNumber + 1);
            } else {
              const data = [
                this.columns.slice(0, 9).map(column => column.title)
              ];
              list.map(item => {
                data.push([
                  ...this.columns.slice(0, 5).map(column => {
                    if (!column.dataIndex) {
                      return;
                    }
                    if (column.customRender) {
                      return column.customRender(item[column.dataIndex], item);
                    } else {
                      return item[column.dataIndex];
                    }
                  }),
                  moment(item.claim_at).format("YYYY-MM-DD HH:mm:ss"),
                  item.use_at
                    ? moment(item.use_at).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                  moment(item.create_time).format("YYYY-MM-DD HH:mm:ss"),
                  moment(item.update_time).format("YYYY-MM-DD HH:mm:ss")
                ]);
              });
              const file = xlsx([
                {
                  name: "用户优惠券列表",
                  data,
                  cols: [
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                  ]
                }
              ]);
              this.$message.success("用户优惠券信息导出成功，正在下载…");
              download(
                file,
                (list[0]?.product_name || "") + "用户优惠券列表.xlsx"
              );
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("用户优惠券信息导出失败");
          });
      getList(1);
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
