var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-coupon-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"用户 ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id']),expression:"['user_id']"}],staticClass:"form-field",attrs:{"placeholder":"请输入用户 ID"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"券码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['coupon_code']),expression:"['coupon_code']"}],staticClass:"form-field",attrs:{"placeholder":"请输入券码"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('div',{staticClass:"actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]),_c('a-button',{on:{"click":_vm.onFilterReset}},[_vm._v(" 清空条件 ")]),_c('a-button',{on:{"click":_vm.onExport}},[_vm._v(" 批量导出"),_c('a-icon',{attrs:{"type":"download"}})],1)],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"time",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")]}},{key:"actions",fn:function(text, record){return (record)?[(record.status === 'unused')?_c('a',{on:{"click":function($event){return _vm.onCancel(record.id)}}},[_vm._v("作废")]):_vm._e()]:undefined}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }